document.addEventListener("turbolinks:load", function(){
  var total_ttfb_conversion_distribution = document.getElementById('total_ttfb_conversion_distribution');
  if(typeof(total_ttfb_conversion_distribution) != 'undefined' && total_ttfb_conversion_distribution != null) {
    var breakdown_array = JSON.parse(total_ttfb_conversion_distribution.dataset.views);
    var cr_array = JSON.parse(total_ttfb_conversion_distribution.dataset.cr);
    var options = {
      colors: ["#111", "#DA4A91"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        height: 450,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth',
        width: [0, 4]
      },
      series: [{
        name: 'Number of Views',
        type: 'column',
        data: [breakdown_array["ms_0_100"], breakdown_array["ms_100_200"], breakdown_array["ms_200_300"], breakdown_array["ms_300_400"], breakdown_array["ms_400_500"], breakdown_array["ms_500_600"], breakdown_array["ms_600_700"], breakdown_array["ms_700_800"], breakdown_array["ms_800_900"], breakdown_array["ms_900_1000"], breakdown_array["ms_1000_1100"], breakdown_array["ms_1100_1200"], breakdown_array["ms_1200_1300"], breakdown_array["ms_1300_1400"], breakdown_array["ms_1400_1500"], breakdown_array["ms_1500_1600"], breakdown_array["ms_1600_1700"], breakdown_array["ms_1700_1800"], breakdown_array["ms_1800_1900"], breakdown_array["ms_1900_2000"], breakdown_array["ms_2000_plus"]]
      }, {
        name: "Conversion Rate",
        type: 'line',
        data: [cr_array["ms_0_100"], cr_array["ms_100_200"], cr_array["ms_200_300"], cr_array["ms_300_400"], cr_array["ms_400_500"], cr_array["ms_500_600"], cr_array["ms_600_700"], cr_array["ms_700_800"], cr_array["ms_800_900"], cr_array["ms_900_1000"], cr_array["ms_1000_1100"], cr_array["ms_1100_1200"], cr_array["ms_1200_1300"], cr_array["ms_1300_1400"], cr_array["ms_1400_1500"], cr_array["ms_1500_1600"], cr_array["ms_1600_1700"], cr_array["ms_1700_1800"], cr_array["ms_1800_1900"], cr_array["ms_1900_2000"], cr_array["ms_2000_plus"]]
      }],
      xaxis: {
        categories: ['0.1s', '0.2s', '0.3s', '0.4s', '0.5s', '0.6s', '0.7s', '0.8s', '0.9s', '1s', '1.1s', '1.2s', '1.3s', '1.4s', '1.5s', '1.6s', '1.7s', '1.8s', '1.9s', '2s', '2+s'],
        axisTicks: {
          show: false
        }
      },
      yaxis: [{
          decimalsInFloat: 0,
          title: {
            text: 'Views',
          },
        }, {
          opposite: true,
          title: {
            text: 'Conversion Rate'
          }
        }],
      legend: {
        horizontalAlign: 'center',
        offsetY: 6
      }
    }

    var chart = new ApexCharts(document.querySelector("#total_ttfb_conversion_distribution"), options);
    chart.render();
  }
});
