document.addEventListener("turbolinks:before-cache", function(){

  var flash = document.getElementById("flash");
  if(typeof(flash) != 'undefined' && flash != null) {
    flash.style.display = "none";
  }

  var explainer = document.getElementById("info_explainer");
  if(typeof(explainer) != 'undefined' && explainer != null) {
    explainer.style.display = "none";
  }

  document.getElementById("full_page_nav").style.width = "0";

});

document.addEventListener("turbolinks:before-cache", function() {
  document.getElementById("dropdown-content").style.display = "none";
});
