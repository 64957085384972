document.addEventListener("turbolinks:load", function(){
  var daily_timings_chart = document.getElementById('daily_page_type_fcp_chart');
  if(typeof(daily_timings_chart) != 'undefined' && daily_timings_chart != null) {
    var daily_total_array = JSON.parse(daily_timings_chart.dataset.total);
    var daily_category_array = JSON.parse(daily_timings_chart.dataset.categories);
    var daily_product_array = JSON.parse(daily_timings_chart.dataset.products);
    var daily_total_data = daily_total_array.map(function(e) {
      return e.load_time;
    });
    var daily_category_data = daily_category_array.map(function(e) {
      return e.load_time;
    });
    var daily_product_data = daily_product_array.map(function(e) {
      return e.load_time;
    });
    var labels = daily_total_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#35469C", "#DA4A91", "#0FB5BA"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'All Pages FCP (ms)',
        data: daily_total_data
      }, {
        name: 'Categories FCP (ms)',
        data: daily_category_data
      }, {
        name: 'Products FCP (ms)',
        data: daily_product_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_page_type_fcp_chart"), options);
    chart.render();
    chart.toggleSeries('All Pages FCP (ms)');
  }
});
