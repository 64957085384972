document.addEventListener("turbolinks:load", function(){
  var crux_lcp_chart = document.getElementById('crux_lcp_chart');
  var data = [];
  if(typeof(crux_lcp_chart) != 'undefined' && crux_lcp_chart != null) {
    var chart_name = crux_lcp_chart.dataset.chartname;

    var website_mobile = JSON.parse(crux_lcp_chart.dataset.website);
    var website_desktop = JSON.parse(crux_lcp_chart.dataset.websiteDesktop);
    var website_name = crux_lcp_chart.dataset.websitename;
    data.push([website_name, website_mobile, website_desktop, "#35469C"]);

    if(typeof(crux_lcp_chart.dataset.competitor0) != "undefined" && crux_lcp_chart.dataset.competitor0 != "{}") {
      var competitor_0_mobile = JSON.parse(crux_lcp_chart.dataset.competitor0);
      var competitor_0_desktop = JSON.parse(crux_lcp_chart.dataset.competitor0Desktop);
      var competitor_0_name = crux_lcp_chart.dataset.competitorname0;
      data.push([competitor_0_name, competitor_0_mobile, competitor_0_desktop, "#DA4A91"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor1) != "undefined" && crux_lcp_chart.dataset.competitor1 != "{}") {
      var competitor_1_mobile = JSON.parse(crux_lcp_chart.dataset.competitor1);
      var competitor_1_desktop = JSON.parse(crux_lcp_chart.dataset.competitor1Desktop);
      var competitor_1_name = crux_lcp_chart.dataset.competitorname1;
      data.push([competitor_1_name, competitor_1_mobile, competitor_1_desktop, "#ffdd59"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor2) != "undefined" && crux_lcp_chart.dataset.competitor2 != "{}") {
      var competitor_2_mobile = JSON.parse(crux_lcp_chart.dataset.competitor2);
      var competitor_2_desktop = JSON.parse(crux_lcp_chart.dataset.competitor2Desktop);
      var competitor_2_name = crux_lcp_chart.dataset.competitorname2;
      data.push([competitor_2_name, competitor_2_mobile, competitor_2_desktop, "#0fbcf9"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor3) != "undefined" && crux_lcp_chart.dataset.competitor3 != "{}") {
      var competitor_3_mobile = JSON.parse(crux_lcp_chart.dataset.competitor3);
      var competitor_3_desktop = JSON.parse(crux_lcp_chart.dataset.competitor3Desktop);
      var competitor_3_name = crux_lcp_chart.dataset.competitorname3;
      data.push([competitor_3_name, competitor_3_mobile, competitor_3_desktop, "#0FB5BA"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor4) != "undefined" && crux_lcp_chart.dataset.competitor4 != "{}") {
      var competitor_4_mobile = JSON.parse(crux_lcp_chart.dataset.competitor4);
      var competitor_4_desktop = JSON.parse(crux_lcp_chart.dataset.competitor4Desktop);
      var competitor_4_name = crux_lcp_chart.dataset.competitorname4;
      data.push([competitor_4_name, competitor_4_mobile, competitor_4_desktop, "#F9703E"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor5) != "undefined" && crux_lcp_chart.dataset.competitor5 != "{}") {
      var competitor_5_mobile = JSON.parse(crux_lcp_chart.dataset.competitor5);
      var competitor_5_desktop = JSON.parse(crux_lcp_chart.dataset.competitor5Desktop);
      var competitor_5_name = crux_lcp_chart.dataset.competitorname5;
      data.push([competitor_5_name, competitor_5_mobile, competitor_5_desktop, "#F9703E"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor6) != "undefined" && crux_lcp_chart.dataset.competitor6 != "{}") {
      var competitor_6_mobile = JSON.parse(crux_lcp_chart.dataset.competitor6);
      var competitor_6_desktop = JSON.parse(crux_lcp_chart.dataset.competitor6Desktop);
      var competitor_6_name = crux_lcp_chart.dataset.competitorname6;
      data.push([competitor_6_name, competitor_6_mobile, competitor_6_desktop, "#F9703E"]);
    };

    if(typeof(crux_lcp_chart.dataset.competitor7) != "undefined" && crux_lcp_chart.dataset.competitor7 != "{}") {
      var competitor_7_mobile = JSON.parse(crux_lcp_chart.dataset.competitor7);
      var competitor_7_desktop = JSON.parse(crux_lcp_chart.dataset.competitor7Desktop);
      var competitor_7_name = crux_lcp_chart.dataset.competitorname7;
      data.push([competitor_7_name, competitor_7_mobile, competitor_7_desktop, "#F9703E"]);
    };

    data.sort(function(a, b) {
      if (a[1] > 0 && b[1] > 0) {
        return a[1] - b[1]
      }
      return b[1] - a[1];
    });

    var names = data.map(function(item) {
      return item[0];
    });

    var mobile = data.map(function(item) {
      return item[1];
    });

    var desktop = data.map(function(item) {
      return item[2];
    });

    var colors = data.map(function(item) {
      return item[3];
    });

    var options = {
      colors: ["#35469C", "#DA4A91"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'bar',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false,
        showForSingleSeries: true
      },
      legend: {
        show: true
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: "Mobile LCP (ms)",
        data: mobile
      }, {
        name: "Desktop LCP (ms)",
        data: desktop
      }],
      xaxis: {
        categories: names,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        show: true
      }
    }

    var chart = new ApexCharts(document.querySelector("#crux_lcp_chart"), options);
    chart.render();
  }
});
