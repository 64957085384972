document.addEventListener("turbolinks:load", function(){
  var daily_crux_chart_desktop = document.getElementById('daily_crux_chart_desktop');
  if(typeof(daily_crux_chart_desktop) != 'undefined' && daily_crux_chart_desktop != null) {
    var label = daily_crux_chart_desktop.dataset.label;
    var unit = daily_crux_chart_desktop.dataset.unit;
    var site_name = daily_crux_chart_desktop.dataset.sitename;
    var daily_site_metrics_array = JSON.parse(daily_crux_chart_desktop.dataset.sitemetrics);
    var daily_site_metrics_data = daily_site_metrics_array.map(function(e) {
      return e.metric;
    });
    var labels = daily_site_metrics_array.map(function(e) {
      return e.created_at;
    });

    var series_data = [];
    series_data.push({
      name: site_name,
      data: daily_site_metrics_data
    });

    if(typeof(daily_crux_chart_desktop.dataset.competitor0metrics) != 'undefined' && daily_crux_chart_desktop.dataset.competitor0metrics != "{}") {
      var competitor0label = daily_crux_chart_desktop.dataset.competitor0;
      var daily_competitor0_metrics_array = JSON.parse(daily_crux_chart_desktop.dataset.competitor0metrics);
      var daily_competitor0_metrics_data = daily_competitor0_metrics_array.map(function(e) {
        return e.metric;
      });
      series_data.push({
        name: competitor0label,
        data: daily_competitor0_metrics_data
      });
    }

    if(typeof(daily_crux_chart_desktop.dataset.competitor1metrics) != 'undefined' && daily_crux_chart_desktop.dataset.competitor1metrics != "{}") {
      var competitor1label = daily_crux_chart_desktop.dataset.competitor1;
      var daily_competitor1_metrics_array = JSON.parse(daily_crux_chart_desktop.dataset.competitor1metrics);
      var daily_competitor1_metrics_data = daily_competitor1_metrics_array.map(function(e) {
        return e.metric;
      });
      series_data.push({
        name: competitor1label,
        data: daily_competitor1_metrics_data
      });
    }

    if(typeof(daily_crux_chart_desktop.dataset.competitor2metrics) != 'undefined' && daily_crux_chart_desktop.dataset.competitor2metrics != "{}") {
      var competitor2label = daily_crux_chart_desktop.dataset.competitor2;
      var daily_competitor2_metrics_array = JSON.parse(daily_crux_chart_desktop.dataset.competitor2metrics);
      var daily_competitor2_metrics_data = daily_competitor2_metrics_array.map(function(e) {
        return e.metric;
      });
      series_data.push({
        name: competitor2label,
        data: daily_competitor2_metrics_data
      });
    }

    if(typeof(daily_crux_chart_desktop.dataset.competitor3metrics) != 'undefined' && daily_crux_chart_desktop.dataset.competitor3metrics != "{}") {
      var competitor3label = daily_crux_chart_desktop.dataset.competitor3;
      var daily_competitor3_metrics_array = JSON.parse(daily_crux_chart_desktop.dataset.competitor3metrics);
      var daily_competitor3_metrics_data = daily_competitor3_metrics_array.map(function(e) {
        return e.metric;
      });
      series_data.push({
        name: competitor3label,
        data: daily_competitor3_metrics_data
      });
    }

    var options = {
      colors: ["#35469C", "#DA4A91", "#ffdd59", "#0fbcf9", "#0FB5BA", "#F9703E"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString() + " " + unit;
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: series_data,
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_crux_chart_desktop"), options);
    chart.render();
  }
});
