document.addEventListener("turbolinks:load", function(){
  var desktop_lighthouse_snapshot_chart = document.getElementById('desktop_lighthouse_snapshot_chart');
  var data = [];
  if(typeof(desktop_lighthouse_snapshot_chart) != 'undefined' && desktop_lighthouse_snapshot_chart != null) {
    var chart_name = desktop_lighthouse_snapshot_chart.dataset.chartname;

    var daily_website_data = JSON.parse(desktop_lighthouse_snapshot_chart.dataset.website);
    var website_name = desktop_lighthouse_snapshot_chart.dataset.websitename;
    data.push([website_name, daily_website_data, "#35469C"]);

    if(typeof(desktop_lighthouse_snapshot_chart.dataset.competitor0) != "undefined" && desktop_lighthouse_snapshot_chart.dataset.competitor0 != "{}") {
      var daily_competitor_0_data = JSON.parse(desktop_lighthouse_snapshot_chart.dataset.competitor0);
      var competitor_0_name = desktop_lighthouse_snapshot_chart.dataset.competitorname0;
      data.push([competitor_0_name, daily_competitor_0_data, "#DA4A91"]);
    };

    if(typeof(desktop_lighthouse_snapshot_chart.dataset.competitor1) != "undefined" && desktop_lighthouse_snapshot_chart.dataset.competitor1 != "{}") {
      var daily_competitor_1_data = JSON.parse(desktop_lighthouse_snapshot_chart.dataset.competitor1);
      var competitor_1_name = desktop_lighthouse_snapshot_chart.dataset.competitorname1;
      data.push([competitor_1_name, daily_competitor_1_data, "#ffdd59"]);
    };

    if(typeof(desktop_lighthouse_snapshot_chart.dataset.competitor2) != "undefined" && desktop_lighthouse_snapshot_chart.dataset.competitor2 != "{}") {
      var daily_competitor_2_data = JSON.parse(desktop_lighthouse_snapshot_chart.dataset.competitor2);
      var competitor_2_name = desktop_lighthouse_snapshot_chart.dataset.competitorname2;
      data.push([competitor_2_name, daily_competitor_2_data, "#0fbcf9"]);
    };

    if(typeof(desktop_lighthouse_snapshot_chart.dataset.competitor3) != "undefined" && desktop_lighthouse_snapshot_chart.dataset.competitor3 != "{}") {
      var daily_competitor_3_data = JSON.parse(desktop_lighthouse_snapshot_chart.dataset.competitor3);
      var competitor_3_name = desktop_lighthouse_snapshot_chart.dataset.competitorname3;
      data.push([competitor_3_name, daily_competitor_3_data, "#0FB5BA"]);
    };

    if(typeof(desktop_lighthouse_snapshot_chart.dataset.competitor4) != "undefined" && desktop_lighthouse_snapshot_chart.dataset.competitor4 != "{}") {
      var daily_competitor_4_data = JSON.parse(desktop_lighthouse_snapshot_chart.dataset.competitor4);
      var competitor_4_name = desktop_lighthouse_snapshot_chart.dataset.competitorname4;
      data.push([competitor_4_name, daily_competitor_4_data, "#F9703E"]);
    };

    data.sort(function(a, b) {
      return a[1] - b[1];
    });

    var names = data.map(function(item) {
      return item[0];
    });

    var series = data.map(function(item) {
      return item[1];
    });

    var colors = data.map(function(item) {
      return item[2];
    });

    var options = {
      colors: colors,
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'bar',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: chart_name,
        data: series
      }],
      xaxis: {
        categories: names,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        show: false
      }
    }

    var chart = new ApexCharts(document.querySelector("#desktop_lighthouse_snapshot_chart"), options);
    chart.render();
  }
});
