// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

require("packs/lightpicker")
require("packs/daily_rag")
require("packs/daily_page_type")
require("packs/total_conversion_distribution")
require("packs/total_dcl_conversion_distribution")
require("packs/total_fcp_conversion_distribution")
require("packs/total_lcp_conversion_distribution")
require("packs/total_ttfb_conversion_distribution")
require("packs/total_cpu_time_conversion_distribution")
require("packs/total_view_distribution")
require("packs/mobile_conversion_distribution")
require("packs/mobile_fcp_conversion_distribution")
require("packs/mobile_lcp_conversion_distribution")
require("packs/desktop_conversion_distribution")
require("packs/desktop_fcp_conversion_distribution")
require("packs/desktop_lcp_conversion_distribution")
require("packs/total_aov_distribution")
require("packs/mobile_aov_distribution")
require("packs/desktop_aov_distribution")
require("packs/daily_percentiles")
require("packs/daily_mobile_vs_desktop")
require("packs/daily_page_type_ttfb")
require("packs/daily_page_type_dcl")
require("packs/daily_page_type_fcp")
require("packs/daily_page_type_lcp")
require("packs/daily_mobile_overview")
require("packs/daily_desktop_overview")
require("packs/daily_mobile_os")
require("packs/daily_desktop_os")
require("packs/daily_mobile_browsers")
require("packs/daily_desktop_browsers")
require("packs/daily_os_android")
require("packs/daily_os_ios")
require("packs/daily_os_mac")
require("packs/daily_os_windows")
require("packs/os_histogram")
require("packs/daily_mobile_lighthouse")
require("packs/daily_desktop_lighthouse")
require("packs/daily_mark_os")
require("packs/mobile_lighthouse_snapshot")
require("packs/desktop_lighthouse_snapshot")
require("packs/turbolinks_before_cache")
require("packs/ui")
require("packs/crux_lcp")
require("packs/daily_lab")
require("packs/daily_crux_chart_mobile")
require("packs/daily_crux_chart_desktop")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
