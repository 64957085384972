document.addEventListener("turbolinks:load", function(){
  var daily_mobile_overview = document.getElementById('daily_mobile_overview');
  if(typeof(daily_mobile_overview) != 'undefined' && daily_mobile_overview != null) {
    var ttfb_array = JSON.parse(daily_mobile_overview.dataset.ttfb);
    var dcl_array = JSON.parse(daily_mobile_overview.dataset.dcl);
    var fcp_array = JSON.parse(daily_mobile_overview.dataset.fcp);
    var lcp_array = JSON.parse(daily_mobile_overview.dataset.lcp);
    var load_time_array = JSON.parse(daily_mobile_overview.dataset.loadtime);
    var ttfb_data = ttfb_array.map(function(e) {
      return e.load_time;
    });
    var dcl_data = dcl_array.map(function(e) {
      return e.load_time;
    });
    var fcp_data = fcp_array.map(function(e) {
      return e.load_time;
    });
    var lcp_data = lcp_array.map(function(e) {
      return e.load_time;
    });
    var load_time_data = load_time_array.map(function(e) {
      return e.load_time;
    });
    var labels = load_time_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#35469C", "#DA4A91", "#ffdd59", "#0fbcf9", "#0FB5BA", "#F9703E"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'Time To First Byte (ms)',
        data: ttfb_data
      }, {
        name: 'DomContentLoaded (ms)',
        data: dcl_data
      }, {
        name: 'First Contentful Paint (ms)',
        data: fcp_data
      }, {
        name: 'Largest Contentful Paint (ms)',
        data: lcp_data
      }, {
        name: 'Load Time (ms)',
        data: load_time_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_mobile_overview"), options);
    chart.render();
  }
});
