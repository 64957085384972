document.addEventListener("turbolinks:load", function(){
  var daily_mobile_os = document.getElementById('daily_mobile_os');
  if(typeof(daily_mobile_os) != 'undefined' && daily_mobile_os != null) {
    var android_array = JSON.parse(daily_mobile_os.dataset.android);
    var ios_array = JSON.parse(daily_mobile_os.dataset.ios);
    var android_data = android_array.map(function(e) {
      return e.load_time;
    });
    var ios_data = ios_array.map(function(e) {
      return e.load_time;
    });
    var labels = ios_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#35469C", "#DA4A91", "#ffdd59", "#0fbcf9", "#0FB5BA", "#F9703E"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'Android (ms)',
        data: android_data
      }, {
        name: 'iOS (ms)',
        data: ios_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_mobile_os"), options);
    chart.render();
  }
});
