document.addEventListener("turbolinks:load", function(){
  var daily_rag_chart = document.getElementById('daily_rag_chart');
  if(typeof(daily_rag_chart) != 'undefined' && daily_rag_chart != null) {
    var daily_green_array = JSON.parse(daily_rag_chart.dataset.green);
    var daily_amber_array = JSON.parse(daily_rag_chart.dataset.amber);
    var daily_red_array = JSON.parse(daily_rag_chart.dataset.red);
    var daily_green_data = daily_green_array.map(function(e) {
      return e.views;
    });
    var daily_amber_data = daily_amber_array.map(function(e) {
      return e.views;
    });
    var daily_red_data = daily_red_array.map(function(e) {
      return e.views;
    });
    var labels = daily_green_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#68cfa6", "#f4d86a", "#e66363"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'bar',
        stacked: true,
        stackType: '100%',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'Fast',
        data: daily_green_data
      }, {
        name: 'Medium',
        data: daily_amber_data
      }, {
        name: "Slow",
        data: daily_red_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        decimalsInFloat: 0
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_rag_chart"), options);
    chart.render();
  }
});
