document.addEventListener("turbolinks:load", function(){
  var daily_desktop_lighthouse_chart = document.getElementById('daily_desktop_lighthouse_chart');
  var series = [];
  if(typeof(daily_desktop_lighthouse_chart) != 'undefined' && daily_desktop_lighthouse_chart != null) {

    var daily_website_array = JSON.parse(daily_desktop_lighthouse_chart.dataset.website);
    var daily_website_data = daily_website_array.map(function(e) {
      return e.time;
    });
    var labels = daily_website_array.map(function(e) {
      return e.created_at;
    });
    var website_name = daily_desktop_lighthouse_chart.dataset.websitename
    series.push({name: website_name, data: daily_website_data});

    if(typeof(daily_desktop_lighthouse_chart.dataset.competitor0) != "undefined" && daily_desktop_lighthouse_chart.dataset.competitor0 != "{}") {
      var daily_competitor_0_array = JSON.parse(daily_desktop_lighthouse_chart.dataset.competitor0);
      var daily_competitor_0_data = daily_competitor_0_array.map(function(e) {
        return e.time;
      });
      var competitor_0_name = daily_desktop_lighthouse_chart.dataset.competitorname0;
      series.push({name: competitor_0_name, data: daily_competitor_0_data});
    };

    if(typeof(daily_desktop_lighthouse_chart.dataset.competitor1) != "undefined" && daily_desktop_lighthouse_chart.dataset.competitor1 != "{}") {
      var daily_competitor_1_array = JSON.parse(daily_desktop_lighthouse_chart.dataset.competitor1);
      var daily_competitor_1_data = daily_competitor_1_array.map(function(e) {
        return e.time;
      });
      var competitor_1_name = daily_desktop_lighthouse_chart.dataset.competitorname1;
      series.push({name: competitor_1_name, data: daily_competitor_1_data});
    };

    if(typeof(daily_desktop_lighthouse_chart.dataset.competitor2) != "undefined" && daily_desktop_lighthouse_chart.dataset.competitor2 != "{}") {
      var daily_competitor_2_array = JSON.parse(daily_desktop_lighthouse_chart.dataset.competitor2);
      var daily_competitor_2_data = daily_competitor_2_array.map(function(e) {
        return e.time;
      });
      var competitor_2_name = daily_desktop_lighthouse_chart.dataset.competitorname2;
      series.push({name: competitor_2_name, data: daily_competitor_2_data});
    };

    if(typeof(daily_desktop_lighthouse_chart.dataset.competitor3) != "undefined" && daily_desktop_lighthouse_chart.dataset.competitor3 != "{}") {
      var daily_competitor_3_array = JSON.parse(daily_desktop_lighthouse_chart.dataset.competitor3);
      var daily_competitor_3_data = daily_competitor_3_array.map(function(e) {
        return e.time;
      });
      var competitor_3_name = daily_desktop_lighthouse_chart.dataset.competitorname3;
      series.push({name: competitor_3_name, data: daily_competitor_3_data});
    };

    if(typeof(daily_desktop_lighthouse_chart.dataset.competitor4) != "undefined" && daily_desktop_lighthouse_chart.dataset.competitor4 != "{}") {
      var daily_competitor_4_array = JSON.parse(daily_desktop_lighthouse_chart.dataset.competitor4);
      var daily_competitor_4_data = daily_competitor_4_array.map(function(e) {
        return e.time;
      });
      var competitor_4_name = daily_desktop_lighthouse_chart.dataset.competitorname4;
      series.push({name: competitor_4_name, data: daily_competitor_4_data});
    };

    var options = {
      colors: ["#35469C", "#DA4A91", "#ffdd59", "#0fbcf9", "#0FB5BA", "#F9703E"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: series,
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_desktop_lighthouse_chart"), options);
    chart.render();
  }
});
