document.addEventListener("turbolinks:load", function(){
  var daily_percentiles_chart = document.getElementById('daily_percentiles_chart');
  if(typeof(daily_percentiles_chart) != 'undefined' && daily_percentiles_chart != null) {
    var label = daily_percentiles_chart.dataset.label;
    var daily_median_array = JSON.parse(daily_percentiles_chart.dataset.median);
    var daily_75_array = JSON.parse(daily_percentiles_chart.dataset.p75);
    var daily_95_array = JSON.parse(daily_percentiles_chart.dataset.p95);
    var daily_median_data = daily_median_array.map(function(e) {
      return e.load_time;
    });
    var daily_75_data = daily_75_array.map(function(e) {
      return e.load_time;
    });
    var daily_95_data = daily_95_array.map(function(e) {
      return e.load_time;
    });
    var labels = daily_95_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#35469C", "#DA4A91", "#ffdd59"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'area',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'Median ' + label + ' (ms)',
        data: daily_median_data
      }, {
        name: '75th Percentile ' + label + ' (ms)',
        data: daily_75_data
      }, {
        name: '95th Percentile ' + label + ' (ms)',
        data: daily_95_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_percentiles_chart"), options);
    chart.render();
  }
});
