document.addEventListener("turbolinks:load", function(){
  var startDate = document.getElementById("litepicker_start");
  var endDate = document.getElementById("litepicker_end");
  var minDate = document.getElementById("litepicker_earliest_day");
  var maxDate = document.getElementById("litepicker_latest_day");
  if (startDate) {
    var picker = new Litepicker({ element: document.getElementById('litepicker'), singleMode: false, startDate: startDate.innerText, endDate: endDate.innerText, minDate: minDate.innerText, maxDate: maxDate.innerText, onSelect: function(date1, date2) { switchDate(date1, date2); } });
  }
});

function switchDate(date1, date2) {
  startDate = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();
  endDate = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
  page_url = location.protocol + '//' + location.host + location.pathname + "?start=" + startDate + "&end=" + endDate;
  window.location.href = page_url;
}

window.openNav = function() {
  document.getElementById("full_page_nav").style.width = "100%";
}
window.closeNav = function() {
  document.getElementById("full_page_nav").style.width = "0";
}

window.toggleDropdown = function() {
  var x = document.getElementById("dropdown-content");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}
