document.addEventListener("turbolinks:load", function(){
  var total_conversion_distribution = document.getElementById('total_conversion_distribution');
  if(typeof(total_conversion_distribution) != 'undefined' && total_conversion_distribution != null) {
    var breakdown_array = JSON.parse(total_conversion_distribution.dataset.views);
    var cr_array = JSON.parse(total_conversion_distribution.dataset.cr);
    var options = {
      colors: ["#111", "#DA4A91"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        height: 450,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth',
        width: [0, 4, 4]
      },
      series: [{
        name: 'Number of Views',
        type: 'column',
        data: [breakdown_array["ms_0_500"], breakdown_array["ms_500_1000"], breakdown_array["ms_1000_1500"], breakdown_array["ms_1500_2000"], breakdown_array["ms_2000_2500"], breakdown_array["ms_2500_3000"], breakdown_array["ms_3000_3500"], breakdown_array["ms_3500_4000"], breakdown_array["ms_4000_4500"], breakdown_array["ms_4500_5000"], breakdown_array["ms_5000_5500"], breakdown_array["ms_5500_6000"], breakdown_array["ms_6000_6500"], breakdown_array["ms_6500_7000"], breakdown_array["ms_7000_7500"], breakdown_array["ms_7500_8000"], breakdown_array["ms_8000_8500"], breakdown_array["ms_8500_9000"], breakdown_array["ms_9000_9500"], breakdown_array["ms_9500_10000"], breakdown_array["ms_10000_plus"]]
      }, {
        name: "Conversion Rate",
        type: 'line',
        data: [cr_array["ms_0_500"], cr_array["ms_500_1000"], cr_array["ms_1000_1500"], cr_array["ms_1500_2000"], cr_array["ms_2000_2500"], cr_array["ms_2500_3000"], cr_array["ms_3000_3500"], cr_array["ms_3500_4000"], cr_array["ms_4000_4500"], cr_array["ms_4500_5000"], cr_array["ms_5000_5500"], cr_array["ms_5500_6000"], cr_array["ms_6000_6500"], cr_array["ms_6500_7000"], cr_array["ms_7000_7500"], cr_array["ms_7500_8000"], cr_array["ms_8000_8500"], cr_array["ms_8500_9000"], cr_array["ms_9000_9500"], cr_array["ms_9500_10000"], cr_array["ms_10000_plus"]]
      }],
      xaxis: {
        categories: ['0.5s', '1s', '1.5s', '2s', '2.5s', '3s', '3.5s', '4s', '4.5s', '5s', '5.5s', '6s', '6.5s', '7s', '7.5s', '8s', '8.5s', '9s', '9.5s', '10s', '10+s'],
        axisTicks: {
          show: false
        }
      },
      yaxis: [{
          decimalsInFloat: 0,
          title: {
            text: 'Views',
          },
        }, {
          opposite: true,
          decimalsInFloat: 0,
          title: {
            text: 'Conversion Rate'
          }
        }],
      legend: {
        horizontalAlign: 'center',
        offsetY: 6
      }
    }

    var chart = new ApexCharts(document.querySelector("#total_conversion_distribution"), options);
    chart.render();
  }
});
