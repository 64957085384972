document.addEventListener("turbolinks:load", function(){
  var daily_desktop_browsers = document.getElementById('daily_desktop_browsers');
  if(typeof(daily_desktop_browsers) != 'undefined' && daily_desktop_browsers != null) {
    var chrome_array = JSON.parse(daily_desktop_browsers.dataset.chrome);
    var safari_array = JSON.parse(daily_desktop_browsers.dataset.safari);
    var firefox_array = JSON.parse(daily_desktop_browsers.dataset.firefox);
    var edge_array = JSON.parse(daily_desktop_browsers.dataset.edge);
    var ie_array = JSON.parse(daily_desktop_browsers.dataset.ie);
    var other_array = JSON.parse(daily_desktop_browsers.dataset.other);
    var chrome_data = chrome_array.map(function(e) {
      return e.load_time;
    });
    var safari_data = safari_array.map(function(e) {
      return e.load_time;
    });
    var firefox_data = firefox_array.map(function(e) {
      return e.load_time;
    });
    var edge_data = edge_array.map(function(e) {
      return e.load_time;
    });
    var ie_data = ie_array.map(function(e) {
      return e.load_time;
    });
    var other_data = other_array.map(function(e) {
      return e.load_time;
    });
    var labels = chrome_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#35469C", "#DA4A91", "#ffdd59", "#0fbcf9", "#0FB5BA", "#F9703E"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'Chrome (ms)',
        data: chrome_data
      }, {
        name: 'Safari (ms)',
        data: safari_data
      }, {
        name: 'Firefox (ms)',
        data: firefox_data
      }, {
        name: 'Edge (ms)',
        data: edge_data
      }, {
        name: "Internet Explorer (ms)",
        data: ie_data
      }, {
        name: "Other Browsers (ms)",
        data: other_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_desktop_browsers"), options);
    chart.render();
  }
});
