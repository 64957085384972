document.addEventListener("turbolinks:load", function(){
  var daily_mobile_vs_desktop_chart = document.getElementById('daily_mobile_vs_desktop_chart');
  if(typeof(daily_mobile_vs_desktop_chart) != 'undefined' && daily_mobile_vs_desktop_chart != null) {
    var label = daily_mobile_vs_desktop_chart.dataset.label;
    var daily_desktop_array = JSON.parse(daily_mobile_vs_desktop_chart.dataset.desktop);
    var daily_mobile_array = JSON.parse(daily_mobile_vs_desktop_chart.dataset.mobile);
    var daily_desktop_data = daily_desktop_array.map(function(e) {
      return e.load_time;
    });
    var daily_mobile_data = daily_mobile_array.map(function(e) {
      return e.load_time;
    });
    var labels = daily_desktop_array.map(function(e) {
      return e.created_at;
    });
    var options = {
      colors: ["#35469C", "#DA4A91"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 450,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toLocaleString()
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },
      series: [{
        name: 'Median Desktop ' + label + ' (ms)',
        data: daily_desktop_data
      }, {
        name: 'Median Mobile ' + label + ' (ms)',
        data: daily_mobile_data
      }],
      xaxis: {
        type: 'datetime',
        categories: labels,
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        decimalsInFloat: 0,
        forceNiceScale: true
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 7
      }
    }

    var chart = new ApexCharts(document.querySelector("#daily_mobile_vs_desktop_chart"), options);
    chart.render();
  }
});
